<template>
  <v-row class="mb-3 mt-6">
    <v-col>
      <vueper-slides
        class="no-shadow"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-3'"
        :visible-slides="3"
        :gap="2"
        :slide-ratio="$vuetify.breakpoint.smAndDown ? 3 / 5 : 1 / 4"
        transition-speed="800"
        :infinite="false"
        :slide-multiple="false"
        :dragging-distance="300"
        :autoplay="true"
        :arrows="$vuetify.breakpoint.mdAndUp ? false : true"
        :bullets="false"
        :breakpoints="{ 800: { visibleSlides: 1, slideMultiple: 1 } }"
      >
        <vueper-slide v-for="(img, i) in images" :key="i.i" :image="img" />
      </vueper-slides>
    </v-col>
  </v-row>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      images: [
        'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727679/Grid%20Images/about_us_jlr_contractors_0_qy8liy.webp',
        'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727679/Grid%20Images/about_us_jlr_contractors_1_cliktt.webp',
        'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727679/Grid%20Images/about_us_jlr_contractors_4_csukzj.webp',
        'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727678/Grid%20Images/about_us_jlr_contractors_3_z7g8et.webp',
        'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727678/Grid%20Images/about_us_jlr_contractors_2_gbsvic.webp'
      ]
    };
  },
};
</script>

<style lang="scss" scoped></style>
