<template>
  <v-row>
    <v-col
      v-if="$vuetify.breakpoint.mdAndUp"
      cols="12"
      md="5"
      class="left-column"
      fill-height
    >
      <div v-html="svg" class="mr-16" />
    </v-col>
    <v-col cols="12" md="7" class="pr-0">
      <v-carousel height="350" :show-arrows="false" hide-delimiter-background cycle continuous>
        <v-carousel-item v-for="(item, i) in items" :key="i" dark>
          <v-sheet height="350" tile color="transparent">
            <v-row
              class="fill-height flex-column"
              :class="$vuetify.breakpoint.smAndDown ? 'text-center mx-auto' : 'px-10'"
              justify="center"
              :align="$vuetify.breakpoint.smAndDown ? 'center' : ''"
            >
              <h2 class="pb-1 font-weight-light">{{ item.header }}</h2>
              <h2 class="display-2 pb-3" v-html="item.mobileTitle && $vuetify.breakpoint.smAndDown ? item.mobileTitle : item.title" />
              <v-btn
                v-if="item.button"
                :max-width="item.btnWidth"
                rounded
                outlined
                color="primary"
                :to="item.to"
              >
                {{ item.button }}
              </v-btn>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      svg: `<svg viewBox="0 0 766.5 172.113">
  <path id="Exclusion_1" data-name="Exclusion 1" d="M94.365,116.58h0L49.513,71.969,12.695,108.588,0,94.7,94.366,0,190,94.7l-13.965,13.888L139.219,71.969,94.366,116.58ZM94.6,24.93,62.663,56.693,94.6,88.454l31.933-31.761L94.6,24.93Z" transform="translate(242 27.766)" fill="#5b5b5b" opacity="0.93"/>
  <path id="Exclusion_4" data-name="Exclusion 4" d="M140.306,172.113h0L73.617,106.251,18.875,160.313,0,139.809,140.307,0,282.5,139.809l-20.763,20.5L207,106.251l-66.689,65.862Zm.343-135.307L93.169,83.7l47.48,46.891L188.129,83.7l-47.48-46.893Z" transform="translate(484)" fill="#5adf4b" opacity="0.93"/>
  <path id="Exclusion_2" data-name="Exclusion 2" d="M94.365,116.58h0L49.513,71.969,12.695,108.588,0,94.7,94.366,0,190,94.7l-13.965,13.888L139.219,71.969,94.366,116.58ZM94.6,24.93,62.663,56.693,94.6,88.454l31.933-31.761L94.6,24.93Z" transform="translate(0 27.766)" fill="#5b5b5b" opacity="0.93"/>
</svg>
`,
    };
  },
};
</script>

<style lang="scss" scoped>
h2 {
  max-width: 80%;
}

::v-deep .left-column {
  position: relative;
  svg {
    position: absolute;
    top: 50%;
    transform: translate(-10%, -50%);
    width: 80%;
    z-index: 0;
  }
}
</style>
