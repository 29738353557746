<template>
  <v-row>
    <v-col>
      <v-parallax
        src="https://res.cloudinary.com/jlr-contractors/image/upload/v1655727656/Banners/07_J_Lyne_Roberts_and_Sons_Inc_-_The_Arnica-10_hzsq0k.webp"
        :height="$vuetify.breakpoint.smAndDown ? 400 : 500"
      >
        <v-overlay absolute opacity=".6" color="black">
          <v-row justify="center">
            <v-col
              cols="12"
              md="10"
              lg="9"
              xl="8"
              class="d-flex"
              :class="
                $vuetify.breakpoint.smAndDown
                  ? 'flex-column align-center text-center'
                  : 'align-center px-16'
              "
            >
              <v-img
                src="https://res.cloudinary.com/jlr-contractors/image/upload/v1655727745/Lifestyle/1634768281870_tkphv4.webp"
                :width="$vuetify.breakpoint.smAndDown ? '150' : '300'"
              />
              <h3
                class="font-weight-light"
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'headline px-5 pt-4'
                    : 'display-1 pl-7'
                "
                v-html="title"
              />
            </v-col>
          </v-row>
        </v-overlay>
      </v-parallax>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      title: `We are proud to have our owner, Scott Roberts, as the President of
        the <span class="primary--text">Utah Home Builders Association</span>`,
    };
  },
};
</script>

<style lang="scss" scoped></style>
