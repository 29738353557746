<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <hr class="mx-8" />
          <span v-html="$store.state.logoSVG" />
          <hr class="mx-8" />
        </div>
        <div
          class="white--text mt-16"
          :class="$vuetify.breakpoint.smAndDown ? 'mx-5' : ''"
        >
          <p
            class="body-1 py-3 mx-auto text-center"
            v-for="item in card.description"
            :key="item.i"
          >
            {{ item }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep svg {
  height: 60px !important;
  path {
    fill: var(--v-primary-base);
  }
}

hr {
  width: 100%;
  border: var(--v-primary-base) solid 1px;
}

p {
  max-width: 900px;
}
</style>
