<template>
  <div>
    <v-row class="full">
      <v-col>
        <v-img
          :src="
            $vuetify.breakpoint.mdAndUp
              ? 'https://res.cloudinary.com/jlr-contractors/image/upload/v1655727697/Heros/aboutUs02_c6vqvf.webp'
              : 'https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1656507452/Heros/ExcellencePhoto-narrow-min_ljgdkj.webp'
          "
          max-width="100vw"
          max-height="99vh"
          min-height="99vh"
          class="pb-6"
          dark
        >
          <div id="socials" v-if="$vuetify.breakpoint.mdAndUp">
            <p class="ml-n5 body-1">Follow us</p>
            <div class="d-flex flex-column align-start mt-9">
              <v-icon
                v-for="social in $store.state.socialsContractors"
                :key="social.i"
                size="30"
                class="my-1"
                @click="openSocial(social.link)"
              >
                {{ social.icon }}
              </v-icon>
            </div>
          </div>
          <hero
            :title="hero.title"
            :titleColored="hero.titleColored"
            :subtitle="hero.subtitle"
          />
        </v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <Slider :items="slider" />
      </v-col>
    </v-row>
    <image-carousel />
    <v-row>
      <v-col class="py-10">
        <title-card :card="card" />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0 pr-0">
        <Quote
          :image="quote.image"
          :imageIndex="quote.imageIndex"
          :imageType="quote.imageType"
          :title="quote.title"
          :height="quote.height"
          :parallax="false"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      :class="$vuetify.breakpoint.mdAndUp ? 'mt-n16 pb-10' : 'mt-16'"
    >
      <v-col
        class="py-0"
        v-for="(item, i) in gridItems"
        :key="item.i"
        cols="12"
        sm="6"
        md="3"
      >
        <GridItem
          page="about"
          :index="i"
          :gridItem="item"
          :image="item.image"
        />
      </v-col>
    </v-row>
    <ImageGrid />
    <v-row>
      <v-col class="py-0">
        <TeamGrid company="JLR Contractors" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="py-16 primary darken-1 d-flex justify-center align-center"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column text-center' : ''"
      >
        <h2
          class="display-2 font-weight-bold white--text"
          :class="$vuetify.breakpoint.smAndDown ? 'pt-12' : ''"
        >
          Want to work for us?
        </h2>
        <v-btn
          to="/careers"
          color="white"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-4 mb-10' : 'ml-10 my-12'"
          rounded
          large
        >
          Join Our Team
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Hero from "@/components/Page/Hero";
import Slider from "@/components/Page/Slider";
import Quote from "@/components/Page/Quote";
import GridItem from "../components/About/GridItem.vue";
import TitleCard from "../components/About/TitleCard.vue";
import TeamGrid from "../components/About/TeamGrid.vue";
import ImageGrid from "../components/About/ImageGrid.vue";
import ImageCarousel from '../components/About/ImageCarousel.vue';
export default {
  components: {
    Hero,
    Slider,
    Quote,
    GridItem,
    TitleCard,
    TeamGrid,
    ImageGrid,
    ImageCarousel,
  },
  data() {
    ImageGrid;
    return {
      metaInfo() {
        return { title: "" };
      },
      employees: [],
      hero: {
        title: "Proudly Serving For Over",
        titleColored: "100 Years",
        subtitle: "There is no substitute for excellence.",
      },
      slider: [
        {
          title: `Unify | <span class="primary--text">Strengthen</span> | Grow.`,
          mobileTitle: `Unify <span class="primary--text">Strengthen</span> Grow.`,
          button: "Work With JLR",
          to: "/contact",
          btnWidth: "180",
        },
        {
          title: `We put <span class="primary--text">people first</span>.`,
          button: "See Our Positions",
          to: "/careers",
          btnWidth: "210",
        },
        {
          title: `There is no substitute for <span class="primary--text">excellence</span>.`,
          button: "Contact Us",
          to: "/contact",
          btnWidth: "150",
        },
      ],
      quote: {
        imageIndex: "about",
        image:
          "https://res.cloudinary.com/jlr-contractors/image/upload/v1655727664/Banners/jlr_quote_img_about_dmysbj.webp",
        imageType: "jpg",
        height: "400",
      },
      gridItems: [
        {
          title: "RESIDENTIAL / REMODEL",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655728702/Banners/ExcellencePhoto_gpcqji.webp",
          link: "/our-work/residential",
        },
        {
          title: "COMMERCIAL / REMODEL",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727742/Heros/Hero_commercial_xq1mff.webp",
          link: "/our-work/commercial",
        },
        {
          title: "PUBLIC WORKS / INDUSTRIAL",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727695/Heros/PublicWorkds2_gtxgil.webp",
          link: "/our-work/public-works",
        },
        {
          title: "ARCHITECTURAL WOODWORK",
          image:
            "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_700/v1655727696/Heros/Hero_architectural_woodwork_hfjnnz.webp",
          link: "/our-work/architectural-woodwork",
        },
      ],
      card: {
        description: [
          `J. Lyne Roberts & Sons was established in 1912 by J. Lyne Roberts Sr. His high school shop teacher was so
        impressed with the quality of his work that he asked Lyne to build his own personal home. That home was the beginning of J. Lyne Roberts & Sons.`,
          `For over 100 years J. Lyne Roberts & Sons (now JLR Contractors) has continued to build and remodel homes, apartments, commercial buildings, city
parks, playgrounds and public works projects with that same quality and integrity that lies at the heart and soul of this family run business.`,
          `Our motto has always been: “there is no substitute for excellence” and we feel that everyone deserves a quality project done on time, safely, and within budget.`,
          `We feel strongly that by treating individuals with kindness and respect we can conduct our business with honesty, integrity, and fairness which, in turn
will create relationships that will benefit all those involved in the process. It is our responsibility to see that we maintain a level of excellence that
raises the bar in the construction industry, and builds lasting relationships.`,
        ],
      },
      socials: [
        {
          icon: "mdi-facebook",
        },
        {
          icon: "mdi-instagram",
        },
        {
          icon: "mdi-linkedin",
        },
      ],
    };
  },
  methods: {
    openSocial(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
#socials {
  position: absolute;
  top: 30%;
  left: 10px;
  z-index: 999;
  p {
    transform: rotate(90deg);
  }
}
</style>
